import logo from './logo.svg';
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import Login from './pages/auth/Login';
import Navbar from './components/Navbar';
import Dashboard from './pages/dashboard/Dashboard';
import ProtectedRoute from './components/utils/ProtectedRoute';

function App() {
  const location = useLocation();
  return (
    <div className="App">
      {location.pathname !== "/login" && <Navbar />}
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route
          path='/emergency_call'
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
