import React, { useEffect, useState } from "react";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { fireStations } from '../data';
import { api } from "../utils/api";

const CreateRequest = () => {
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(false);
  const getEmergencyCall = () => {
    api("emergencycalls/", "get", false, false, true)
      .then((res) => {
          setLoading(true);
          console.log("res", res);
          setCalls(res.data);
      })
      .catch((err) => {
          console.log("err", err);
      })
      .finally(() => {
          setLoading(false); // Set loading to false after the API call is complete
      });
  }
    useEffect(() => {
      getEmergencyCall();
    }, []);
    console.log("calls", calls);
    const IsAccepted = (request) => {
      return (
        <button title="Save" class={`cursor-pointer flex items-center ${request=="Accepted"?"fill-lime-400 bg-lime-950 hover:bg-lime-900 active:border active:border-lime-400":"fill-red-400 bg-red-950 hover:bg-red-900 active:border active:border-red-400"} rounded-md duration-100 p-2`}>
          <span class={`text-sm ${request=="Accepted"?"text-lime-400":"text-red-400"} font-bold pr-1`}>{request}</span>
        </button>
      );
    }
  return (
    <>
        <div className="my-5">
          <div className="shadow-custom p-5 w-full bg-white">
            <p className="font-bold text-slate-700">All Emergency Call</p>
            <hr className="my-6" />
            { loading?<p>Loading...</p>:
              calls && calls?.map((item, index) => {
                return (
                  <div className="my-5 w-full cursor-pointer overflow-hidden relative transition-all duration-500 hover:translate-y-2 h-44 bg-neutral-50 rounded-lg shadow-custom flex flex-row items-center justify-evenly gap-2 p-2 before:absolute before:w-full hover:before:top-0 before:duration-500 before:-top-1 before:h-1 before:bg-red-200">
                    <div className="w-1/4 flex justify-center">
                      <svg
                        className="shrink-0"
                        height="40"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 100 100"
                        width="50"
                        x="0"
                        xmlns="http://www.w3.org/2000/svg"
                        y="0"
                      >
                        <WhatshotIcon className="cursor-pointer text-red-400" />
                      </svg>
                    </div>
                    <div className="w-[75%]">
                      <span className="font-bold">Calles: {item.caller_name} ({item.caller_phone_number})</span>
                      <p className="line-clamp-3">Location: {item.incident_location} ({item.incident_type})</p>
                      <p className="line-clamp-3">Landmark: {item.nearest_landmark} ({item.incident_type})</p>
                    </div>
                    <div>
                      {IsAccepted(item.status)}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
    </>
  );
};

export default CreateRequest;
