import React, { useEffect, useRef, useState } from 'react';
import SnackbarWithDecorators, { changeText } from '../../Utils';
import { useJsApiLoader, Autocomplete, DistanceMatrixService } from '@react-google-maps/api';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { fireStations } from '../data';
import './request.css';
import { api } from '../utils/api';

const EmergencyForm = ({user}) => {
    const [request, setRequest] = useState({
        name: "",
        phone: "",
        nearest: "",
        address: "",
        type: "",
        notes: "",
        description: "",
        priority: "Low",
        latitude: null,
        longitude: null,
    });
    const [station, setStation] = useState(null);
    const [loading, setLoading] = useState(false);
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyCIo70I6zlId1jiMcIlUqO37mrZojLhsos",
        libraries: ['places']
    });
    const [googlePlaceSelected, setGooglePlaceSelected] = useState(false);

    const [snackAlert, setSnackAlert] = useState(false); // popup success or error
    const [snackbarProperty, setSnackbarProperty] = useState({ // popup success or error text
        text: '',
        color: ''
    });

    const autocompleteRef = useRef(null);
    const addressAutocompleteRef = useRef(null);
    
    const handleNearestPlaceChanged = async () => {
        const place = addressAutocompleteRef.current.getPlace();
        if (place.geometry) {
            try {
                setLoading(true);
                const nearest = place.formatted_address;
                setRequest(prevRequest => ({
                    ...prevRequest,
                    nearest
                }));
                const location = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                };
                console.log("location", location);
                setRequest(prevRequest => ({
                    ...prevRequest,
                    latitude: location.lat,
                    longitude: location.lng,
                }));
                // await findNearestFireStation(location);
            } catch (error) {
                console.log("error", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleAddressPlaceChanged = () => {
        const place = autocompleteRef.current.getPlace();
        if (place.geometry) {
            const address = place.formatted_address;
            setGooglePlaceSelected(true);
            setRequest(prevRequest => ({
                ...prevRequest,
                address
            }));
        }
    };

    const handleAddressChange = (e) => {
        const address = e.target.value;
        setGooglePlaceSelected(false);
        setRequest(prevRequest => ({
            ...prevRequest,
            address
        }));
    };

    // const findNearestFireStation = async (location) => {
    //     const service = new window.google.maps.DistanceMatrixService();
    //     const destinations = fireStations?.map(station => station.address);

    //     service.getDistanceMatrix({
    //         origins: [location],
    //         destinations: destinations,
    //         travelMode: 'DRIVING',
    //         unitSystem: window.google.maps.UnitSystem.METRIC,
    //     }, (response, status) => {
    //         if (status === 'OK') {
    //             const distances = response?.rows[0]?.elements;
    //             const sortedStations = distances?.map((distance, index) => ({
    //                 ...fireStations[index],
    //                 distance: distance?.distance?.value
    //             })).sort((a, b) => a?.distance - b?.distance);
                
    //             setStation(sortedStations);
    //             setRequest(prevRequest => ({
    //                 ...prevRequest,
    //                 nearest: sortedStations[0].name
    //             }));
    //         }
    //     });
    // };

    const handleEmergencyCall = () => {
        const {
        name,
        phone,
        nearest,
        address,
        type,
        notes,
        description,
        priority,
        latitude,
        longitude,
        } = request;
       
        if(name === "" || nearest === "" || address === "" || type === "" || notes === "" || description === "" || priority === "" || latitude === null || latitude === undefined || longitude === null || longitude === undefined  ){
            setSnackbarProperty(prevState => ({
                ...prevState,
                text: "All fields are required",
                color: "danger"
              }));
            setSnackAlert(true);
            return;
        } else{
            const phoneNumberPattern = /^\d{10}$/; // This pattern matches exactly 10 digits
            if (!phoneNumberPattern.test(phone)) {
                setSnackbarProperty(prevState => ({
                    ...prevState,
                    text: "Please enter a valid 10-digit phone number.",
                    color: "danger"
                    }));
                setSnackAlert(true);
                return;
            } else {
                const body = {
                    user: user.id,
                    caller_name: request.name,
                    caller_phone_number: request.phone,
                    incident_location: request.address,
                    nearest_landmark: request.nearest,
                    incident_type: request.type,
                    description_of_incident: request.description,
                    nature_of_emergency: request.priority,
                    additional_notes: request.notes,
                    latitude: request.latitude,
                    longitude: request.longitude
                };
            
                setLoading(true); // Set loading to true before making the API call
            
                api("emergencycalls/", "post", body, false, true)
                .then((res) => {
                    console.log("res", res);
                    setStation(res.data);
                })
                .catch((err) => {
                    console.log("err", err);
                })
                .finally(() => {
                    setLoading(false); // Set loading to false after the API call is complete
                });
            }
        }
    }

    const assign_firestation = (id) => {
        const body = {
            fire_station_id: id
        }
        const body1 = {
            status: "Pending"
        }
        api(`emergencycalls/${station.id}/assign-firestation/`, "post", body, false, true)
        .then((res) => {
            console.log("res", res);
                api(`emergency-calls/${station.id}/status/`, "patch", body1, false, true)
                .then((res) => {
                    console.log("res", res);
                    return;
                })
                .catch((err) => {
                    console.log("err", err);
                })
                .finally(() => {
                    setLoading(false); // Set loading to false after the API call is complete
                });
            setSnackbarProperty(prevState => ({
                ...prevState,
                text: "Fire station assigned successfully",
                color: "success"
              }));
            setSnackAlert(true);
            return;
        })
        .catch((err) => {
            console.log("err", err);
        })
        .finally(() => {
            setLoading(false); // Set loading to false after the API call is complete
        });
    }

    useEffect(() => {
        if (station) {
            setRequest(prevRequest => ({
                ...prevRequest,
                nearest: station.name
            }));
            console.log("station", station);
        }
    }, [station]);
    useEffect(() => {
        console.log("request", request);
    }, [request]);

    return (
        <>
            {
                snackAlert?
                <SnackbarWithDecorators snackAlert={snackAlert} setSnackAlert={setSnackAlert} text={snackbarProperty.text} color={snackbarProperty.color} />
                :null
            }
            {!isLoaded ? "Loading..." :
                <div className='my-5 sm:flex sm:gap-7'>
                    <div className='max-h-full shadow-custom p-5 w-full rounded-sm bg-white'>
                        <p className='font-bold text-slate-700'>Create Emergency Request</p>
                        <hr className='my-6' />
                        <div className='my-3'>
                            <label htmlFor="name" className="mb-2 text-sm text-start text-grey-900">Name*</label>
                            <input id="name" type="text" name="name" onChange={(e) => changeText(e, setRequest, request)} placeholder="John Doe" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                        </div>
                        <div className='my-3'>
                            <label htmlFor="phone" className="mb-2 text-sm text-start text-grey-900">Phone*</label>
                            <input id="phone" type="number" name="phone" onChange={(e) => changeText(e, setRequest, request)} placeholder="0123456789" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                        </div>
                        <div className='my-3'>
                            <label htmlFor="address" className="mb-2 text-sm text-start text-grey-900">Address*</label>
                            <Autocomplete
                                onLoad={autocomplete => autocompleteRef.current = autocomplete}
                                onPlaceChanged={handleAddressPlaceChanged}
                            >
                                <input onChange={handleAddressChange} id="address" type="text" name="address" value={request.address} placeholder="Enter your address" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                            </Autocomplete>
                        </div>
                        <div className='my-3'>
                            <label htmlFor="nearest" className="mb-2 text-sm text-start text-grey-900">Nearest Place*</label>
                            <Autocomplete
                                onLoad={autocomplete => addressAutocompleteRef.current = autocomplete}
                                onPlaceChanged={handleNearestPlaceChanged}
                            >
                                <input id="nearest" type="text" name="nearest" placeholder="Enter nearest place" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                            </Autocomplete>
                        </div>
                        <div className='my-3'>
                            <label htmlFor="emergency" className="mb-2 text-sm text-start text-grey-900">Emergency Type*</label>
                            <input id="emergency" type="text" name="type" onChange={(e) => changeText(e, setRequest, request)} placeholder="Describe your emergency" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                        </div>
                        <div className='my-3'>
                            <label htmlFor="description" className="mb-2 text-sm text-start text-grey-900">Description Of Incident*</label>
                            <textarea
                                onChange={(e) => changeText(e, setRequest, request)}
                                name="description"
                                id="description"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="Additional notes"
                            />
                        </div>
                        <div className='my-3'>
                            <label htmlFor="priority" className="mb-2 text-sm text-start text-grey-900">Priority*</label>
                            <select onChange={(e) => changeText(e, setRequest, request)} name='priority' id="priority" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                <option selected value="Low">Low</option>
                                <option value="Medium">Medium</option>
                                <option value="High">High</option>
                            </select>
                        </div>
                        <div className='my-3'>
                            <label htmlFor="notes" className="mb-2 text-sm text-start text-grey-900">Notes*</label>
                            <textarea
                                onChange={(e) => changeText(e, setRequest, request)}
                                name="notes"
                                id="notes"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="Additional notes"
                            />
                        </div>
                        <hr className='my-4' />
                        <div className='flex justify-end'>
                            <button onClick={handleEmergencyCall} type="button" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2">Next</button>
                        </div>
                    </div>
                    <div className='w-full'>
                        <div className='shadow-custom p-5 w-full bg-white'>
                            <div className="flex gap-7">
                                <p className='font-bold text-slate-700'>Nearby station</p>
                            </div>
                            <hr className='my-6' />
                        {
                            loading ? "Loading...":(
                                    station && (
                                            station?.nearest_fire_stations?.map((item, index) => {
                                                return (
                                                    <>
                                                        <div
                                                        key={index}
                                                        className="my-5 w-full cursor-pointer overflow-hidden relative transition-all duration-500 hover:translate-y-2 h-44  shadow-custom flex flex-row items-center justify-evenly gap-2 p-2 before:absolute before:w-full hover:before:top-0 before:duration-500 before:-top-1 before:h-1 before:bg-red-200"
                                                        >
                                                            <svg
                                                                className="shrink-0"
                                                                height="40"
                                                                preserveAspectRatio="xMidYMid meet"
                                                                viewBox="0 0 100 100"
                                                                width="50"
                                                                x="0"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                y="0"
                                                            >
                                                                <WhatshotIcon className='cursor-pointer text-red-400' />
                                                            </svg>
                                                            <div>
                                                                <div className="flex justify-between gap-5">
                                                                    <span className="font-bold">{item.name}</span>
                                                                </div>
                                                                
                                                                <p className="line-clamp-3">
                                                                    {item.address}
                                                                </p>
                                                            </div>
                                                            
                                                            <button
                                                                onClick={() => {assign_firestation(item.id)}}
                                                                class="text-white bg-gradient-to-r from-red-500 via-red-600 to-red-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                                                                type="button"
                                                            >
                                                                Assign
                                                            </button>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        )
                                    )
                                }
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default EmergencyForm;
