export const fireStations = [
    {
        "name": "Adajan Fire Station",
        "officer": "Officer A",
        "vehicles": [
            {
                "type": "Engine",
                "count": 2
            },
            {
                "type": "Ladder",
                "count": 1
            }
        ],
        "address": "Opp. Star Bazar, Bh. Alishan Enclave, Surat-Hazira Road, Surat, Gujarat, India"
    },
    {
        "name": "Morabhagal Fire Station",
        "officer": "Officer B",
        "vehicles": [
            {
                "type": "Engine",
                "count": 3
            },
            {
                "type": "Rescue",
                "count": 1
            }
        ],
        "address": "Opp. Jamia Hussainia Hostel, Morabhal Char Rasta, Morabhagal, Surat, Gujarat, India"
    },
    {
        "name": "Majura Gate Fire Station",
        "officer": "Officer C",
        "vehicles": [
            {
                "type": "Tanker",
                "count": 2
            }
        ],
        "address": "Opp. Jolly Plaza, Besides Old LB Cinema, Majura Gate, Surat, Gujarat, India"
    },
    {
        "name": "Muglisara Fire Station",
        "officer": "Officer C",
        "vehicles": [
            {
                "type": "Tanker",
                "count": 2
            }
        ],
        "address": "Besides SMC HQ, Muglisara Main Road, Surat, Gujarat, India"
    },
    {
        "name": "Navsari Bazar Fire Station",
        "officer": "Officer C",
        "vehicles": [
            {
                "type": "Tanker",
                "count": 2
            }
        ],
        "address": "Navsari Bazar Char Rasta, Opp. Mobin Hospital, Kot Sofil Road, Surat, Gujarat, India"
    },
    {
        "name": "Ganchi Sheri Fire Station",
        "officer": "Officer C",
        "vehicles": [
            {
                "type": "Tanker",
                "count": 2
            }
        ],
        "address": "Navapura, Ganchi Sheri, Surat, Gujarat, India"
    },
    {
        "name": "Kosad Fire Station",
        "officer": "Officer C",
        "vehicles": [
            {
                "type": "Tanker",
                "count": 2
            }
        ],
        "address": "Nr. Kosad Health Center, Opp. Ganeshpura Front, Amroli Sayan Road, Surat	"
    },
    {
        "name": "Katargam Fire Station",
        "officer": "Officer C",
        "vehicles": [
            {
                "type": "Tanker",
                "count": 2
            }
        ],
        "address": "Nr. Dholakia Garden, Katargamgam Road, Surat, Gujarat, India"
    },
    {
        "name": "Kapodra Fire Station",
        "officer": "Officer C",
        "vehicles": [
            {
                "type": "Tanker",
                "count": 2
            }
        ],
        "address": "Nr. Kapodra Police Station, Varachha-Kapodra Road, Surat, Gujarat, India"
    },
    {
        "name": "Pandesara Fire Station",
        "officer": "Officer C",
        "vehicles": [
            {
                "type": "Tanker",
                "count": 2
            }
        ],
        "address": "Pandesara GIDC, Road No. C, Nr. Bank of Baroda, Pandesara, Surat, Gujarat, India"
    },
    {
        "name": "Mandarwaja Fire Station",
        "officer": "Officer C",
        "vehicles": [
            {
                "type": "Tanker",
                "count": 2
            }
        ],
        "address": "Besides Ambedkar Shopping Center, Ring Road, Surat, Gujarat, India"
    },
    {
        "name": "Dumbhal Fire Station",
        "officer": "Officer C",
        "vehicles": [
            {
                "type": "Tanker",
                "count": 2
            }
        ],
        "address": "Nr. South East Zone Office, Model Town Road, Dumbhal, Surat, Gujarat, India"
    }
];